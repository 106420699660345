import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./See-All.css";
import { useNavigate, useParams } from "react-router-dom";
import default_post from "../../Assets/images/default-post.jpeg"
import NotFoundPost from "../../Assets/NoPostFound";
import Loading from "../../Assets/Loading";

function SeeAllPosts() {
  const [cars, setCars] = useState([]);
  const [location, setLocation] = useState(""); 
  const [category, setCategory] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 50;

  const { locationParam, categoryParam  } = useParams();
  const navigate = useNavigate();

  const getCars = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/allposts`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setCars(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, []);

// Free Classified ads in Ahmdedabad post ads post ads Classified in Ahmedabad - Quiko
useEffect(() => {
  getCars();
  setCurrentPage(1); // Reset to the first page whenever filters change
  window.scrollTo(0, 0);// Scroll to the top of the page when the component mounts
  document.title = `Free Classified ads in ${locationParam || "All Locations"} ${categoryParam ? categoryParam + " category" : ""} - Quiko`;
}, [getCars, locationParam, categoryParam]);

useEffect(() => {
  if (locationParam) setLocation(locationParam);
  if (categoryParam) setCategory(categoryParam);
}, [locationParam, categoryParam]);

// Update URL when location changes
const handleLocationChange = (e) => {
  const selectedLocation = e.target.value;
  setLocation(selectedLocation);

  if (selectedLocation) {
    if (category && category !== "all") {
      navigate(`/p/${selectedLocation}/${category}`);
    } else {
      navigate(`/p/${selectedLocation}`);
    }
  } else {
    navigate("/p");
  }
};

// Update URL when category changes
const handleCategoryChange = (e) => {
  const selectedCategory = e.target.value;
  setCategory(selectedCategory);

  if (selectedCategory && selectedCategory !== "all") {
    navigate(`/p/${location}/${selectedCategory}`);
  } else {
    navigate(`/p/${location}`);
  }
};

// Helper function to update URL with location and category
// const updateURL = (location, category) => {
//   const path = `/p/${location || "all"}/${category || "all"}`;
//   navigate(path);
// };

  // Filter cars based on selected location and category
  const filteredCars = useMemo(() => {
    return cars.filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerCategory = category.toLowerCase();

      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&

            (curEle.category && curEle.category.toLowerCase().includes(lowerCategory))
      );
    }).sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }, [cars, location, category]);

  if (cars.length === 0) {
    return <Loading />;
  }
  if (filteredCars.length === 0) {
    return <NotFoundPost/>;
  }

  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;
  const currentCars = filteredCars.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(filteredCars.length / carsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPaginationNumbers = () => {
    const pageNumbers = [];
    if (totalPages > 1) {
      if (currentPage === 1) {
        pageNumbers.push(1, 2, '...');
      } else if (currentPage === 2) {
        pageNumbers.push(1, 2, 3);
      } else if (currentPage >= 3) {
        pageNumbers.push(currentPage - 1, currentPage, '...');
      }
    } else if (totalPages === 1) {
      pageNumbers.push(1);
    }
    return pageNumbers;
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const isLatest = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const differenceInHours = (now - postDate) / (1000 * 60 * 60);
    return differenceInHours <= 24;
  };

  const handleCardClick = (_id, category) => {
    navigate(`/${category}/${_id}`);
  };

  return (
    <div className="all-cars-container" style={{ marginTop: "20px" }}>
      <h1 className="homecard-title bikes user-profile-title" id="homecard-text" style={{ width: "100%" , maxWidth:"100%", }}>
        All Posts in {location}
      </h1>
      <div className="filter-container">
        {/* Location Dropdown */}
        <div className="filter-wrapper">
          <label htmlFor="location">Select Location:</label>
          <select
            id="location"
            value={location}
            onChange={handleLocationChange}
            className="custom-select"
          >
            <option value="">All Locations</option>
            <option value="Ahmedabad">Ahmedabad</option>
            <option value="Delhi">Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Kolkata">Kolkata</option>
            <option value="Chennai">Chennai</option>
            <option value="Pune">Pune</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Jaipur">Jaipur</option>
            <option value="Gurgaon">Gurgaon</option>
          </select>
        </div>

        {/* Category Dropdown */}
        <div className="filter-wrapper">
          <label htmlFor="category">Select Category:</label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
            className="custom-select"
          >
            <option value="">All Categories</option>
            <option value="car">Cars</option>
            <option value="bikes">Bikes</option>
            <option value="mobiles">Mobiles</option>
            <option value="electronics">Electronics</option>
            <option value="jobs">Jobs</option>
            <option value="furnitures">Furnitures</option>
            <option value="hostels">Hostel & PGs</option>
            <option value="selfdrives">Self-Drives</option>
            <option value="cloths">Cloths</option>
            <option value="renthouse">Rent House</option>
            <option value="salehouse">Sale House</option>
            <option value="rentshop">Rent Shop</option>
            <option value="saleshop">Sale Shop</option>
            <option value="plots">Plots</option>
            <option value="others">Others</option>
          </select>
        </div>
      </div>

      <div className="all-cars" style={{ marginTop: "20px", marginLeft:"-20px" }}>
        {currentCars.map((curEle, index) => {
          const { _id, title, imageUrl, price, aria, createdAt, category, rent, isPremium } = curEle;
          return (
            <div className="sell-all-post-child" key={index}>
              <div className="child post-container" onClick={() => handleCardClick(_id, category)}  style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }  >
                {isLatest(createdAt) && <span className="latest-label">Latest</span>}
                {isPremium && <span className="premium-badge">FEATURED</span>}
                <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Car"  onError={(e) => (e.target.src = default_post)}/>
                <h4>Rs {price || rent}</h4>
                <h6>{timeAgo(createdAt)}</h6>
                <h5>{title}</h5>
                <h6>Location: {aria}</h6>
                <h6>Click for More Details</h6>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination Controls */}
      <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        )}
        {getPaginationNumbers().map((page, i) => (
          <button
            key={i}
            onClick={() => typeof page === 'number' && paginate(page)}
            className={currentPage === page ? "active" : ""}
            disabled={page === '...'}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default SeeAllPosts;
