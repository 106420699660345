import React from 'react'

function TermsPolicy() {
  return (
    <div style={{marginTop:"50px" , marginLeft:"10px"}}>
      <h1>OVERVIEW</h1>
      <p>
        This website is operated by Quiko. Throughout the site, the terms “we”,
        “us” and “our” refer to Quiko. Quiko offers this website, including all
        information, tools, and services available from this site to you, the
        user, conditioned upon your acceptance of all terms, conditions,
        policies, and notices stated here.
      </p>
      <p>
        By visiting our site and/or purchasing something from us, you engage in
        our “Service” and agree to be bound by the following terms and
        conditions (“Terms of Service”, “Terms”), including those additional
        terms and conditions and policies referenced herein and/or available by
        hyperlink. These Terms of Service apply to all users of the site,
        including without limitation users who are browsers, vendors, customers,
        merchants, and/or contributors of content.
      </p>
      <p>
        Please read these Terms of Service carefully before accessing or using
        our website. By accessing or using any part of the site, you agree to be
        bound by these Terms of Service. If you do not agree to all the terms
        and conditions of this agreement, then you may not access the website or
        use any services.
      </p>
      <p>
        Any new features or tools which are added to the current store shall
        also be subject to the Terms of Service. We reserve the right to update,
        change, or replace any part of these Terms of Service by posting updates
        and/or changes to our website. Your continued use of or access to the
        website following the posting of any changes constitutes acceptance of
        those changes.
      </p>

      <h2>SECTION 1 - ONLINE STORE TERMS</h2>
      <p>
        By agreeing to these Terms of Service, you represent that you are at
        least the age of majority in your state or province of residence, or
        that you are the age of majority in your state or province of residence
        and you have given us your consent to allow any of your minor dependents
        to use this site.
      </p>
      <p>
        You may not use our products for any illegal or unauthorized purpose nor
        may you, in the use of the Service, violate any laws in your
        jurisdiction (including but not limited to copyright laws).
      </p>
      <p>
        A breach or violation of any of the Terms will result in an immediate
        termination of your Services.
      </p>

      <h2>SECTION 2 - GENERAL CONDITIONS</h2>
      <p>
        We reserve the right to refuse service to anyone for any reason at any
        time.
      </p>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any
        portion of the Service, use of the Service, or access to the Service or
        any contact on the website through which the service is provided,
        without express written permission by us.
      </p>

      <h2>SECTION 3 - ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION</h2>
      <p>
        We are not responsible if information made available on this site is not
        accurate, complete, or current. The material on this site is provided
        for general information only and should not be relied upon or used as
        the sole basis for making decisions without consulting primary, more
        accurate, complete, or timely sources of information. Any reliance on
        the material on this site is at your own risk.
      </p>

      <h2>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h2>
      <p>
        Prices for our products are subject to change without notice. We reserve
        the right at any time to modify or discontinue the Service without
        notice.
      </p>

      <h2>SECTION 5 - PRODUCTS OR SERVICES</h2>
      <p>
        Certain products or services may be available exclusively online through
        the website. These products or services may have limited quantities and
        are subject to return or exchange only according to our Return Policy.
      </p>
      <p>
        We have made every effort to display as accurately as possible the
        colors and images of our products that appear in the store. We cannot
        guarantee that your computer monitor's display of any color will be
        accurate.
      </p>

      <h2>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
      <p>
        We reserve the right to refuse any order you place with us. We may, in
        our sole discretion, limit or cancel quantities purchased per person,
        per household, or per order. These restrictions may include orders
        placed under the same customer account, credit card, or shipping
        address.
      </p>

      <h2>SECTION 7 - OPTIONAL TOOLS</h2>
      <p>
        We may provide you with access to third-party tools over which we
        neither monitor nor have any control. You acknowledge and agree that we
        provide access to such tools “as is” and “as available” without any
        warranties, representations, or conditions of any kind.
      </p>

      <h2>SECTION 8 - THIRD-PARTY LINKS</h2>
      <p>
        Certain content, products, and services available via our Service may
        include materials from third parties. We are not responsible for
        examining or evaluating the content or accuracy of third-party websites
        and have no liability for third-party materials.
      </p>

      <h2>SECTION 9 - USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS</h2>
      <p>
        You agree that we may, at any time, without restriction, edit, copy,
        publish, distribute, or otherwise use any comments you forward to us. We
        are under no obligation to maintain comments in confidence or respond to
        them.
      </p>

      <h2>SECTION 10 - PERSONAL INFORMATION</h2>
      <p>
        Your submission of personal information through the store is governed by
        our Privacy Policy.
      </p>

      <h2>SECTION 11 - ERRORS, INACCURACIES, AND OMISSIONS</h2>
      <p>
        Occasionally there may be information on our site that contains
        typographical errors, inaccuracies, or omissions related to product
        descriptions, pricing, promotions, offers, and availability. We reserve
        the right to correct such errors and cancel orders if needed.
      </p>

      <h2>SECTION 12 - PROHIBITED USES</h2>
      <p>
        You are prohibited from using the site or its content for any unlawful
        purpose, to solicit others to perform unlawful acts, or to violate any
        laws, regulations, or rights of others. We reserve the right to
        terminate your use of the Service for violating prohibited uses.
      </p>

      <h2>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
      <p>
        We do not guarantee that your use of our service will be uninterrupted,
        timely, secure, or error-free. The service and all products are provided
        "as is" and "as available" without any warranties or conditions of any
        kind.
      </p>
      <p>
        In no case shall Quiko, its directors, employees, or agents be liable
        for any direct or indirect damages of any kind arising from your use of
        the service.
      </p>

      {/* Add more sections as needed */}

    </div>
  )
}

export default TermsPolicy
