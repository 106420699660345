import React from "react";
import "./CityExplorer.css";
import { useNavigate } from "react-router-dom";

const cities = [
  {
    name: "Mumbai",
    image:
      "https://images.unsplash.com/photo-1601961405399-801fb1f34581?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Gateway of India",
  },
  {
    name: "Bangalore",
    image:
      "https://plus.unsplash.com/premium_photo-1697729606148-42a74a5bad37?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Vidhana Soudha",
  },
  {
    name: "Delhi",
    image:
      "https://plus.unsplash.com/premium_photo-1661919589683-f11880119fb7?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "India Gate",
  },
  {
    name: "Kolkata",
    image:
      "https://images.unsplash.com/photo-1496372412473-e8548ffd82bc?q=80&w=1614&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Victoria Memorial",
  },
  {
    name: "Ahmedabad",
    image:
      "https://images.unsplash.com/photo-1713260443292-ec016608ca42?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Atal Bridge",
  },
  {
    name: "Chennai",
    image:
      "https://plus.unsplash.com/premium_photo-1675803530828-412b1fda4587?q=80&w=1633&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "xyz ",
  },
  {
    name: "Jaipur",
    image:
      "https://plus.unsplash.com/premium_photo-1697729831106-dbca67df36af?q=80&w=1633&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: "Jaipur ",
  },
  {
    name: "Pune",
    image:
      "https://images.unsplash.com/photo-1553064483-f10fe837615f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Hyderabad",
    image:
      "https://plus.unsplash.com/premium_photo-1694475128245-999b1ae8a44e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Surat",
    image:
      "https://images.unsplash.com/photo-1679549199016-1e941a541de3?q=80&w=1494&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Lucknow",
    image:
      "https://plus.unsplash.com/premium_photo-1697729447666-c39f50d595ea?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Amritsar",
    image:
      "https://plus.unsplash.com/premium_photo-1697730426305-113c62434f97?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Indore",
    image:
      "https://plus.unsplash.com/premium_photo-1697730407363-4fff46d992bb?q=80&w=1575&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Agra",
    image:
      "https://plus.unsplash.com/premium_photo-1697729441569-f706fdd1f71c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    name: "Chandigarh",
    image:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcQ--FttZL4lCuwRH00zdZ2f0GJlO4wo1bjdNwsP2_lMJ1lYvebW2kkh8IksHuHC1oOS",
  },
  {
    name: "Vadodara",
    image:
      "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcRjCCfJi1qVvQaZseSR-DAnhYfTg-qPUo6AOhN-HOn4wT1GMB_ILd5lxFB3WtsJUf23",
  },
  {
    name: "Rajkot",
    image:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcQYxfAmEPOEaVzNUVoMJZV1xz53SW1jNpiPjn9ZPeQd-pvjWyffcB2y0mNGt1TNE2CW",
  },
  {
    name: "Kanpur",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/61/Kanpur_skyline.jpg",
  },
  {
    name: "Patna",
    image:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTm6EmTHo6eHnWDNXfTpiceXxYDqIUgbnIV2YKcWvMxSOQwUKyHP_6Lny7qOx04foN1",
  },
  {
    name: "Nagpur",
    image:
      "https://www.google.com/url?sa=i&url=http%3A%2F%2Ft3.gstatic.com%2Flicensed-image%3Fq%3Dtbn%3AANd9GcS_ivu_CpDXFb_RBs8WtNDHMEY2Kp668Gz6mPKAO8rQljGf9SthnbpVnXgQ4wCAh56I&psig=AOvVaw2q9l0SvJlM-nmbOiVGm_BU&ust=1731146198138000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCOCHm9y9zIkDFQAAAAAdAAAAABAY",
  },
  {
    name: "Nashik",
    image:
      "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcR_ETNT1cbDVRCipbBFIyWKos7qPH9sIg7B7uubGLaP9aQws8mLaCGdqygnlBugJp0V",
  },
  {
    name: "Visakhapatnam",
    image:
      "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQvW_1qyBk_69uLUA2bWjnNO1Kjd4pqAwDXsW40Y83_MuzG_qygh_ptujgoaFxouLMh",
  },
  {
    name: "Udaipur",
    image:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcR83sUKimpryUmi6FU2CFY74-EpwbyMI_DSifdgCb06-RM90V5N0ajHZtHzwKNBj1TW",
  },
  {
    name: "Bhopal",
    image:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcSqNKPupN_Yjcyicsh5tY4zszdjLvRgGaxpkaJhnZ-xqanxRfpK9UCXkhUSEpHyPT0O",
  },

  // Add more cities as needed
];

function CityExplorer() {
  const navigate = useNavigate();

  return (
    <div className="city-explorer">
      <h2 style={{color:"blue"}}>
        Explore Top Cities <span className="new-label">NEW</span>
      </h2>
      <div className="city-cards">
        {cities.map((city) => (
          <div
            key={city.name}
            className="city-card"
            onClick={() => navigate(`/p/${city.name}`)}
          >
            <img src={city.image} alt={city.name} className="city-image" />
            <div className="city-info">
              <h3>{city.name.toUpperCase()}</h3>
              {/* <p>{city.description}</p> */}
              <a href={`/p/${city.name}`} className="explore-link">
                Explore &gt;
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CityExplorer;
