import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/auth";
import default_pic from "../default-pic.png";
import { toast } from "react-toastify";

function UserProfile({ setIsLoading }) {
  const { user, authorizationToken } = useAuth();
  const navigate = useNavigate();
  const handlePost = () => navigate("/yourpost");

  const [address, setAddress] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [showOtpDiv, setShowOtpDiv] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [phone, setPhone] = useState(user.phone);
  const [createdAt, setCreatedAt] = useState(user.createdAt);

  useEffect(() => {
    const fetchUserProfile = async () => {
      
      try {
        const response = await fetch("https://in.quiko.in/api/auth/user", {
          method: "GET",
          headers: {
            Authorization: authorizationToken,
          },
        });
        const data = await response.json();

        setAddress(data.userData.address || "Address not found");
        setProfilePhoto(data.userData.profilePhoto || default_pic);
        setUsername(data.userData.username || user.username);
        setPhone(data.userData.phone || user.phone || "Add your Number");
        setCreatedAt(data.userData.createdAt || user.createdAt || "2024" );
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [authorizationToken, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("address", address);
    formData.append("phone", phone);
    if (e.target.image.files[0]) {
      formData.append("profilePhoto", e.target.image.files[0]);
    }

    setIsLoading(true)
    try {
      const response = await fetch("https://in.quiko.in/api/user/userpic/update", {
        method: "PUT",
        headers: {
          Authorization: authorizationToken,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setProfilePhoto(data.profilePhoto || default_pic);
        toast.success("Profile Edit Successfully")
        setShowOtpDiv(!showOtpDiv);
      } else {
        console.error("Error updating user profile:", data);
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }finally{
      setIsLoading(false)
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).getFullYear(); // Return only the year
  };

  return (
    <>
      <h1 className="homecard-title bikes user-profile-title" style={{marginTop:"30px", width:"100%",}}>Your Profile
      </h1>
      <div className="profile-card-container">
        <div className="profile-background">
          <img
            className="profile-logo"
            src= {`https://in.quiko.in/${profilePhoto}`}
            alt={`${username}'s Profile`}
            onError={(e) => {
              e.target.onerror = null; 
              e.target.src = default_pic;
            }}
          />
        </div>
        <h1 className="bold-text">{username}</h1>
        <h2 className="normal-text">Username</h2>
        <hr />
        <div className="hj">
          <div className="followers">
            <h1 className="bold-text">{phone}</h1>
            <h2 className="smaller-text">Phone No</h2>
          </div>
          <div className="likes">
            <h1 className="bold-text">{user.email}</h1>
            <h2 className="smaller-text">Email</h2>
          </div>
          <div className="photos">
            <h1 className="bold-text">{address}</h1>
            <h2 className="smaller-text">Address</h2>
          </div>
          <div className="photos">
            <h1 className="bold-text">{formatDate(createdAt)}</h1>
            <h2 className="smaller-text">Member Since </h2>
          </div>
        </div>
        <div className="social-container">
          <div className="edit-btn">
            <button
              className="log-in-btn"
              onClick={(e) => {
                e.preventDefault();
                setShowOtpDiv(!showOtpDiv);
              }}
            >
              Edit
            </button>
          </div>
          <div className="Your-post-btn">
            <button className="sign-up-btn" onClick={handlePost}>
              Your Post
            </button>
          </div>
        </div>
      </div>

      {showOtpDiv && (
        <div className="profile-card-container">
          <div className="profile-background">
            <h2>Edit Your Profile</h2>
            <br />
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputText" className="form-label">
                  Upload your profile photo
                </label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  id="image"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  id="address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone No
                </label>
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <button type="submit" className="form-submit-button">
                Submit
              </button>
              <br />
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default UserProfile;
