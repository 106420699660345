import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MySvg from "../Assets/images/pay-scanner.svg";

function FeaturedPost() {
  const location = useLocation();
  const { email, postId, postTitle, category } = location.state || {};

  const [phoneNumber, setPhoneNumber] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [showQRForm, setShowQRForm] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create payload with email, postId, phoneNumber, and transactionId
    const payload = {
      email,
      postId,
      phoneNumber,
      transactionId,
      category,
    };

    try {
      // Send the data to the backend (replace the URL with your actual backend endpoint)
      const response = await fetch("https://in.quiko.in/api/admin/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // If form submission was successful, you can show success message
        setFormSubmitted(true);
        alert("Form submitted successfully!");
      } else {
        alert("There was a problem with the submission.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  //================== razorpay payment code in desctop file ================== if want
  // ============================================================

  // Function to dynamically configure Razorpay payment
  useEffect(() => {
    const loadRazorpay = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        // console.log('Razorpay script loaded');
        setRazorpayLoaded(true);
      };
      document.head.appendChild(script);
    };
    loadRazorpay();
  }, []);

  const handlePayment = async () => {
    if (!razorpayLoaded) {
      console.error("Razorpay SDK not loaded");
      return;
    }

    const options = {
      key: "rzp_live_Gi01MJQTbulZvM", // Replace with your Razorpay Key ID
      amount: "9900", // Amount in paise (9900 paise = 99 INR)
      currency: "INR",
      name: "Featured Post Payment",
      description: "Payment for featuring your post",
      image: "https://quiko.in/static/media/olx-logo.aa23a4719d9ef7a659de.png",
      handler: function (response) {
        alert(
          `Payment successful. Payment ID: ${response.razorpay_payment_id} 
           your will be active within 24 to 48 hours
          `
        );
      },
      prefill: {
        name: "User  Name",
        email: email,
      },
      notes: {
        postId: postId,
        category:category,
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Featured Post</h1>
          <br />
          <p className="featured-post-P">
            - Welcome to the Featured Post section! When a user makes a payment,
            their post will automatically move to this section within 24 hours,
            giving it increased visibility and a special spotlight.
          </p>
          <p className="featured-post-P">
            - Featured posts stay here for 30 days, after which they are
            automatically removed to make room for new featured listings. This
            ensures that all posts receive the attention they deserve, keeping
            our platform fresh and up-to-date.
          </p>
          <p className="featured-post-P">- Pay ₹99 only </p>
          <p className="featured-post-P">* Your Post Title : {postTitle}</p>
          <p className="featured-post-P">* Your Email Id : {email}</p>

          <div className="payment-btn-container">
          <button
            className="razorpay-btn"
            onClick={() => handlePayment()}
            disabled={!razorpayLoaded}
          >
            Pay with Razorpay 
          </button>
          {/* Button to show/hide QR code and form */}
          <button
            className="payment-submit-btn"
            onClick={() => setShowQRForm((prev) => !prev)}
          >
            {showQRForm ? "Hide QR Payment" : "Pay with QR Direct"}
          </button>
          </div>

          {showQRForm && (
            <>
          <div className="svg-container">
            <img
              src={MySvg}
              alt="Featured Post Icon"
              className="featured-post-svg"
            />
          </div>
          
          {/* Form to submit phone number and transaction ID */}
          {!formSubmitted ? (
            <form className="transaction-form" onSubmit={handleSubmit}>
              <div className="payment-form-group">
                <label htmlFor="phoneNumber">
                  Personal Phone Number: <br /> (if any query we will contact
                  you )
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="payment-form-group">
                <label htmlFor="transactionId">UPI Transaction ID:</label>
                <input
                  type="text"
                  id="transactionId"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  required
                  placeholder="Enter your transaction ID"
                />
              </div>
              <button type="submit" className="payment-submit-btn">
                Submit Details
              </button>
            </form>
          ) : (
            <p>
              Thank you! Your details have been submitted. <br /> Your Post
              Active In 24 Hours
            </p>
          )}
            </>
          )}

        </div>
      </div>
    </div>
  );
}

export default FeaturedPost;
