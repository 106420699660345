import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../store/auth";
import { toast } from "react-toastify";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import map_logo from "../../../map-icon.png"
import number_logo from "../../../Assets/91.png"

export default function EditSaleShop({setIsLoading}) {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    rent: "",
    furnishing: "",
    listedby: "",
    builtuparea: "",
    carpetarea: "",
    maintanance: "",
    parking: "",
    washrooms: "",
    facing: "",
    projectname: "",
    title: "",
    description: "",
    aria: "" ,
    wnumber:"",
  });


  useEffect(() => {
    // Fetch car details and set the form data
    const fetchCarDetails = async () => {
      try {
        const response = await fetch(`https://in.quiko.in/api/sell/saleshop/${id}`, {
          method: "GET",
        });
        const data = await response.json();
        const dataValue = data.response_data;

        if(response.ok){
          setFormData({
            rent: dataValue.rent,
            furnishing: dataValue.furnishing,
            listedby: dataValue.listedby,
            builtuparea: dataValue.builtuparea,
            carpetarea: dataValue.carpetarea,
            maintanance: dataValue.maintanance,
            parking: dataValue.parking,
            washrooms: dataValue.washrooms,
            facing: dataValue.facing,
            projectname: dataValue.projectname,
            title: dataValue.title,
            description: dataValue.description,
            aria: dataValue.aria,
            wnumber: dataValue.wnumber,
          });
        }
        
      } catch (error) {
        console.log("Error fetching Post details: ");
      }
    };

    fetchCarDetails();
  }, [id]);

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, aria: value || "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.rent >= 0 && formData.rent <= 10) {
      toast.error("Please enter a Rent greater than 10.");
      return;
    }

    setIsLoading(true)
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/saleshop/edit/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        toast.success("Post details updated successfully!");
        navigate("/");
      } else {
        toast.error("Failed to update Post details. Post not found");
      }
    } catch (error) {
      toast.error("Error updating Post details.");
    }finally{
      setIsLoading(false)
    }
  };

  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setFormData({ ...formData, aria: value }); // Update location in formData
    setErrorMessage(""); // Clear any previous error message

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location found"); // Set error message if fetch fails
      }
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-content">
        <h1>Rent : Shops & Office </h1>
        <form onSubmit={handleSubmit}>

           <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Rent*
            </label>
            <input
              type="Number"
              name="rent"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.rent}
              onChange={handleChange}
            />
            {formData.rent >= 0 && formData.rent <= 10 && (
               <div style={{ color: "red" }}>
                Please enter a Rent greater than 10.
                </div>
            )}
          </div>

          <div className="mb-3">
            <select
              name="furnishing"
              className="form-select form-select-sm"
              aria-label="Small select example"
              onChange={handleChange}
              value={formData.furnishing}
            >
              <option selected="">Furnishing*</option>
              <option value="Furnished">Furnished</option>
              <option value="Semi-Furnished">Semi-Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
          </div>


          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="listedby"
              onChange={handleChange}
              value={formData.listedby}
            >
              <option selected="">Listed by*</option>
              <option value="Builder">Builder</option>
              <option value="Dealer">Dealer</option>
              <option value="Owner">Owner</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Super Builtup area (ft^2)*
            </label>
            <input
              type="number"
              name="builtuparea"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.builtuparea}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Carpet area (ft^2)*
            </label>
            <input
              type="number"
              name="carpetarea"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.carpetarea}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Maintanance
            </label>
            <input
              type="number"
              name="maintanance"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.maintanance}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="parking"
              onChange={handleChange}
              value={formData.parking}
            >
              <option selected="">Car Parking*</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3+">3+</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Washrooms
            </label>
            <input
              type="number"
              name="washrooms"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.washrooms}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="facing"
              onChange={handleChange}
              value={formData.facing}
            >
              <option selected="">Facing </option>
              <option value="East">East</option>
              <option value="North">North</option>
              <option value="North-East">North-East</option>
              <option value="North-West">North-West</option>
              <option value="South">South</option>
              <option value="South-East">South-East</option>
              <option value="South-West">South-West</option>
              <option value="West">West</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Project Name
            </label>
            <input
              type="text"
              name="projectname"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.projectname}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Ad title*
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              // id="exampleInputNumber"
              value={formData.title}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Description*
            </label>
            <textarea
              type="text"
              name="description"
              className="form-control"
              cols="30"
              rows="4"
              // id="exampleInputNumber"
              value={formData.description}
              onChange={handleChange}
            />
          </div>


          <div className="mb-3">
            <label htmlFor="aria" className="form-label">
              Location*
            </label>
            <Autocomplete
              style={{
                width: "100%",
                height: 50,
                background: "white",
                borderRadius: 20,
              }}
              freeSolo
              autoComplete
              autoHighlight
              options={myOptions}
              onInputChange={getDataFromAPI} // Update suggestions on input change
              onChange={handleAutocompleteChange} // Set full location on selection
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  
                  placeholder="Select your Area, State"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      height: 50,
                      borderRadius: 20,
                      border: "1px solid black",
                      fontSize: "16px",
                      fontWeight: "700",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={map_logo}
                          alt="Search Icon"
                          style={{ width: 26, height: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorMessage}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Your Whatsapp Number*
            </label>
            <TextField
              type="text"
              name="wnumber"
              className="form-control"
              id="wnumber"
              placeholder=" 1234567890"
              
              value={formData.wnumber}
              onChange={handleChange}
              InputProps={{
                
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={number_logo} // Your logo image
                      alt="Logo"
                      style={{ width: 50, height: 50 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>        

          <button type="submit" className="form-submit-button">
            Update Post
          </button>
        </form>
      </div>
    </div>
  );
}
