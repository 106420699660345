import React from "react";
import { Link } from "react-router-dom";
import logo from "../olx-logo.png";

export default function Footer() {
  return (
    <div>
      <footer className="footer-section">
        <div className="container">
          {/* <div className="footer-cta pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 mb-30 secondlast">
                                <div className="single-cta">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>Motera Ahmedabad</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 last">
                                <div className="single-cta">
                                    <i className="fas fa-phone"></i>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>+91 9909552207</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 end">
                                <div className="single-cta">
                                    <i className="far fa-envelope-open"></i>
                                    <div className="cta-text texting">
                                        <h4>Mail us</h4>
                                        <span>quikosell@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      Your trusted marketplace for buying, selling, and
                      discovering the best deals on everything you need. Join
                      millions of users and find the perfect item near you
                      today!
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.youtube.com/@quiko_now" target="__blank">
                    <i className="fa-brands fa-youtube youtube-bg"></i>
                    </a>
                    <Link to="/">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </Link>
                    <a href="https://www.instagram.com/quiko_now/" target="__blank">
                       <i className="fa-brands fa-instagram insta-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Nearby Locations</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/p/ahmedabad" >Ahmedabad</Link>
                    </li>
                    <li>
                    <Link to="/p/delhi" >Delhi</Link>
                    </li>
                    <li>
                    <Link to="/p/kolkata" >Kolkata</Link>
                    </li>
                    <li>
                    <Link to="/p/bangalore" >Bangalore</Link>
                    </li>
                    <li>
                    <Link to="/p/gurgaon" >Gurgaon</Link>
                    </li>
                    <li>
                    <Link to="/p/hyderabad" >Hyderabad</Link>
                    </li>
                    <li>
                    <Link to="/p/chennai" >Chennai</Link>
                    </li>
                    <li>
                    <Link to="/p/jaipur" >Jaipur</Link>
                    </li>
                    <li>
                    <Link to="/p/pune" >Pune</Link>
                    </li>
                    <li>
                    <Link to="/p/mumbai" >Mumbai</Link>
                    </li>
                    <li>
                    <Link to="/all-cities" >All Cities</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="/">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="fab fa-telegram-plane"></i>
                      </button>
                    </form>
                  </div>
                  <div className="single-cta" style={{paddingTop:"40px"}}>
                    <i className="far fa-envelope-open"></i>
                    <div className="cta-text texting">
                      <h4>Mail us</h4>
                      <span>quikosell@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container footer-container-mobile">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; 2024, All Right Reserved{" "}
                    <Link to="/">Quiko</Link>
                  </p>
                  {/* <p style={{fontSize:"12px"}}>Designed by- <Link to="https://www.linkedin.com/in/darshan9094/" target='_blank'>Darshan</Link></p> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <a href="/refund" target="__blank"> Refund</a>
                    </li>
                    <li>
                      <a href="/terms" target="__blank"> Terms</a>
                    </li>
                    <li>
                      <a href="privacy-policy" target="__blank"> Privacy Policy</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
