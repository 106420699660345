import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import default_pic from "../default-pic.png";
import "./UserProfile.css";
import { useAuth } from "../store/auth";

function UserProfilePosted() {
  const { email } = useParams();
  const { authorizationToken } = useAuth();
  const location = useLocation();
  const { aria, wnumber } = location.state || {}; // Retrieve aria and wnumber from location state

  const [address, setAddress] = useState(aria || "Address Not Found");
  const [profilePhoto, setProfilePhoto] = useState(default_pic);
  const [username, setUsername] = useState("unknown user");
  const [phone, setPhone] = useState(wnumber || "Add your Number");
  const [createdAt, setCreatedAt] = useState("2024");

  useEffect(() => {
    if (!email) {
      console.error("Email parameter is missing.");
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const response = await fetch(
          `https://in.quiko.in/api/auth/user/${email}`,
          {
            method: "GET",
            headers: {
              Authorization: authorizationToken,
            },
          }
        );

        if (response.status === 404) {
          // User not found, handle gracefully without throwing an error
          //   console.log("User not found. Displaying default values.");
          return; // Default values will be used
        }

        // if (!response.ok) {
        // //   throw new Error(`HTTP error! status: ${response.status}`);
        // return
        // }

        const data = await response.json();
        setAddress(data.user.address || address);
        setProfilePhoto(data.user.profilePhoto || default_pic);
        setUsername(data.user.username || username);
        setPhone(data.user.phone || phone);
        setCreatedAt(data.user.createdAt || createdAt);
      } catch (error) {
        // Handle other errors
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [authorizationToken, email, address, phone, username, createdAt]);

  const formatDate = (dateString) => {
    return new Date(dateString).getFullYear(); // Return only the year
  };

  return (
    <>
      <h1
        className="homecard-title bikes user-profile-title"
        style={{ marginTop: "30px", width: "100%", fontSize:"30px" }}
      >
        User Profile
      </h1>
      <div className="profile-card-container">
        <div className="profile-background">
          <img
            className="profile-logo"
            src={`https://in.quiko.in/${profilePhoto}`}
            alt={`${username}'s Profile`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_pic;
            }}
          />
        </div>
        <h1 className="bold-text">{username}</h1>
        <h2 className="normal-text">Username</h2>
        <hr />
        <div className="hj">
          <div className="followers">
            <h1 className="bold-text">{phone}</h1>
            <h2 className="smaller-text">Phone No</h2>
          </div>
          <div className="likes">
            <h1 className="bold-text">{email}</h1>
            <h2 className="smaller-text">Email</h2>
          </div>
          <div className="photos">
            <h1 className="bold-text">{address}</h1>
            <h2 className="smaller-text">Address</h2>
          </div>
          <div className="photos">
            <h1 className="bold-text">{formatDate(createdAt)}</h1>
            <h2 className="smaller-text">Member Since</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfilePosted;
