import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { FaUser, FaHome,FaShoppingBag, FaMoneyCheckAlt  } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import "./Admin.css"
import { useAuth } from "../../store/auth";
import { useCallback, useEffect, useState } from "react";


export const AdminLayout = () => {

  const { authorizationToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true); // Start with loading state
  const [isAdmin, setIsAdmin] = useState(null);

  const navigate = useNavigate();


  const userAdmin = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/auth/user`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok && data.userData) {
        setIsAdmin(data.userData.isAdmin); // Update the isAdmin state

      }
    } catch (error) {
      console.log("Error fetching user data:");
    } finally {
      setIsLoading(false); // Set loading to false after data fetch
    }
  }, [authorizationToken]);

  useEffect(() => {
    userAdmin();
  }, [userAdmin]);



  if(isLoading){
    return <h1>Loading ....</h1>
  }
  
  if (!isAdmin) {
    // If the user is not an admin after loading is complete
    return <Navigate to="/" />;
  }


    function handleUsers (){
        navigate("/admin/users")
    }
    function handleContact (){
        navigate("/admin/contacts")
    }
    function handleSell (){
        navigate("/admin/sell")
    }
    function handleFeatured (){
        navigate("/admin/featured")
    }
    function handlePayment (){
        navigate("/admin/payment")
    }
    function handleHome (){
        navigate("/")
    }
  return (
    <>
      <nav className="navbar navbar-expand-lg  bg-primary" data-bs-theme="dark" style={{marginTop: "150px"}}>
        <div className="container-fluid">
        <p className="admin">Admin</p>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="list-group list-group-horizontal">
              <li className="list-group-item" onClick={handleUsers}>
                 <FaUser/> Users
              </li>
              <li className="list-group-item" onClick={handleContact}>
                 <FaMessage/> Contact
              </li>
              <li className="list-group-item" onClick={handleSell}>
                 <FaShoppingBag /> Sell
              </li>
              <li className="list-group-item" onClick={handleFeatured}>
                 <FaMoneyCheckAlt  /> Featured
              </li>
              <li className="list-group-item" onClick={handlePayment}>
                 <FaMoneyCheckAlt  /> Payment
              </li>
              <li className="list-group-item" onClick={handleHome}>
                 <FaHome/> Home
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
};
