import React, { useEffect } from 'react';
import CityExplorer from '../Card/CityExplorer';
import CityList from '../Card/CityList';

function Allcities() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="App">
      <CityExplorer />
      <CityList/>
    </div>
  );
}

export default Allcities;
