import React from 'react';
import './PopupCard.css';

const NotFoundPost = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Post Not Found</h2>
        <p>We couldn't find the post you're looking for. It may have been removed or the URL might be incorrect.</p>
        <button onClick={() => window.location.href = '/'}>Go Back to Home</button>
      </div>
    </div>
  );
};

export default NotFoundPost;