import {
    CitySelect,
    StateSelect,
  } from "react-country-state-city";
  import "react-country-state-city/dist/react-country-state-city.css";
  import { useState } from "react";
  import "./CityExplorer.css";
  
  function CityList() {
    const indiaCountryId = 101; // India country ID
    const [stateid, setstateid] = useState(0);
    const [cityName, setCityName] = useState(""); // Track the selected city name
  
    const handleCitySelection = () => {
      if (cityName) {
        // Navigate to /p/cityname by setting window location to the selected city name
        window.location.href = `/p/${cityName}`;
      }
    };
  
    return (
        <>
      <div className="city-list-container">
        <h2 style={{color:"blue" , marginBottom:"30px"}}>Explore More Cities</h2>
        <div className="select-container">
          <div className="select-box-container">
            <p className="citylist-title">State</p>
            <StateSelect
              countryid={indiaCountryId}
              onChange={(e) => {
                setstateid(e.id);
              }}
              placeHolder="Select State"
              className="select-box"
            />
          </div>
  
          <div className="select-box-container">
            <p className="citylist-title">City</p>
            <CitySelect
              countryid={indiaCountryId}
              stateid={stateid}
              onChange={(e) => {
                setCityName(e.name); // Set selected city name
              }}
              placeHolder="Select City"
              className="select-box"
            />
          </div>
        </div>
  
        <button
          onClick={handleCitySelection}
          className="navigate-button"
          disabled={!cityName} // Disable button if no city is selected
        >
          Explore
        </button>
      </div>
      </>
    );
  }
  
  export default CityList;
  