import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../olx-logo.png";
// import React,  from "react";
// import { Link,  } from "react-router-dom";
// import OlxLogo from '../Assets/OlxLogo';
import "../Components/Navbar.css";
// import logo from "../olx-logo.png";
import { useAuth } from "../store/auth";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";

const SignupForm = ({setIsLoading}) => {
  const { storeTokenInLs } = useAuth();

  const [user, setUser] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  function handleLogin() {
    navigate("/login");
  }
  function handleHome() {
    navigate("/");
  }
  function handleSignup() {
    navigate("/signup");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)
    try {
      const Response = await fetch(`https://in.quiko.in/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const res_data = await Response.json();
      if (Response.ok) {
        storeTokenInLs(res_data.token);
        setUser({ username: "", email: "", phone: "", password: "" });
        toast.success("Login successfull");
        navigate("/");
      } else {
        toast.error(
          res_data.extraDetails ? res_data.extraDetails : res_data.message
        );
      }
    } catch (error) {
      console.log("register", error);
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    // Reload the page if no hash exists
    if (!window.location.hash) {
      // Set a timeout to allow React to finish rendering before reload
      const timer = setTimeout(() => {
        window.location.hash = "reloaded";
        window.location.reload();
      }, 0);

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, []);

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await fetch(
        `https://in.quiko.in/api/auth/google-login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id_token: response.credential }),
        }
      );

      const data = await res.json();
      if (res.ok) {
        // localStorage.setItem("token", data.token);
        storeTokenInLs(data.token);
        toast.success("Registration successful");
        navigate("/");
      } else {
        toast.error(data.message || "Registration failed");
      }
    } catch (error) {
      toast.error("An error occurred during registration");
    }
  };

  return (
    <div>
      <div className="back" style={{ height: "75px" }}>
        <div className="main">
          <div className="olxlogo" onClick={handleHome}>
            <img src={logo} alt="OLX Logo" />
          </div>
          {/* <div className="carlogo">
            <img
              src="https://static.vecteezy.com/system/resources/previews/001/193/785/non_2x/car-png.png"
              alt="Car Logo"
            />
            <span>MOTORS</span>
          </div> */}
          {/* <div className="buildinglogo">
            <img
              src="https://freepngimg.com/save/148529-building-vector-png-download-free/512x512"
              alt="Building Logo"
            />
            <span>PROPERTY</span>
          </div> */}
          <div className=" login-btn ">
            <button
              onClick={handleLogin}
              type="button"
              // className="btn btn-primary login"
              className="log-in-btn"
            >
              Login
            </button>
            <button
              onClick={handleSignup}
              type="button"
              // className="btn btn-light login"
              className="sign-up-btn"
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
      <div className="contact-container" style={{ marginTop: "90px" }}>
        <div className="contact-content">
          <h1>Signup </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleInputname" className="form-label">
                Username
              </label>
              <input
                type="text"
                name="username"
                className="form-control"
                id="exampleInputname"
                value={user.username}
                onChange={handleInput}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={user.email}
                onChange={handleInput}
                required
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputnumber" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                name="phone"
                className="form-control"
                id="exampleInputnumber"
                placeholder="Enter your number with you country code "
                value={user.phone}
                onChange={handleInput}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type="password"
                name="password"
                className="form-control"
                id="exampleInputPassword1"
                value={user.password}
                onChange={handleInput}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Signup
            </button>

            <div style={{marginTop:"10px", width:"100%"}}></div>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={() => toast.error("Google registration failed")}
            />
            <div/>
            
          </form>
        </div>
      </div>
      <div>
      <footer className="footer-section">
        <div className="container">
          {/* <div className="footer-cta pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 mb-30 secondlast">
                                <div className="single-cta">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>Motera Ahmedabad</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 last">
                                <div className="single-cta">
                                    <i className="fas fa-phone"></i>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>+91 9909552207</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 end">
                                <div className="single-cta">
                                    <i className="far fa-envelope-open"></i>
                                    <div className="cta-text texting">
                                        <h4>Mail us</h4>
                                        <span>quikosell@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      Your trusted marketplace for buying, selling, and
                      discovering the best deals on everything you need. Join
                      millions of users and find the perfect item near you
                      today!
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.youtube.com/@quiko_now" target="__blank">
                    <i className="fa-brands fa-youtube youtube-bg"></i>
                    </a>
                    <Link to="/">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </Link>
                    <a href="https://www.instagram.com/quiko_now/" target="__blank">
                       <i className="fa-brands fa-instagram insta-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Our Locations</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/login">Ahmedabad</Link>
                    </li>
                    <li>
                      <Link to="/login">Delhi</Link>
                    </li>
                    <li>
                      <Link to="/login">Mumbai</Link>
                    </li>
                    <li>
                      <Link to="/login">Bangalore</Link>
                    </li>
                    <li>
                      <Link to="/login">Vadodara</Link>
                    </li>
                    <li>
                      <Link to="/login">Hyderabad</Link>
                    </li>
                    <li>
                      <Link to="/login">Kolkata</Link>
                    </li>
                    <li>
                      <Link to="/login">Noida</Link>
                    </li>
                    <li>
                      <Link to="/login">Jaipur</Link>
                    </li>
                    <li>
                      <Link to="/login">Gandhinagar</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="/">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="fab fa-telegram-plane"></i>
                      </button>
                    </form>
                  </div>
                  <div className="single-cta" style={{paddingTop:"40px"}}>
                    <i className="far fa-envelope-open"></i>
                    <div className="cta-text texting">
                      <h4>Mail us</h4>
                      <span>quikosell@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; 2024, All Right Reserved{" "}
                    <Link to="/">Quiko</Link>
                  </p>
                  {/* <p style={{fontSize:"12px"}}>Designed by- <Link to="https://www.linkedin.com/in/darshan9094/" target='_blank'>Darshan</Link></p> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Terms</Link>
                    </li>
                    <li>
                      <Link to="/">Privacy</Link>
                    </li>
                    <li>
                      <Link to="/">Policy</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </div>
    </div>
  );
};

export default SignupForm;
