import React, {  useRef } from 'react'
import Add from '../Components/Add'
import Category from '../Components/Category'
import EndAdd from '../Components/EndAdd'
import HomeCard from '../Components/HomeCard'
import HomeCard1 from '../Components/HomeCard1'
import HomeCard2 from '../Components/HomeCard2'
import HomeCard3 from '../Components/HomeCard3'
import HomeCard4 from '../Components/HomeCard4'
import HomeCard5 from '../Components/HomeCard5'
import HomeCard6 from '../Components/HomeCard6'
import HomeCard7 from '../Components/HomeCard7'
// import HomeCard8 from '../Components/HomCard8'
import BannerAdd from '../Components/BannerAdd'
import HomeCard8 from '../Components/HomCard8'
import HomeCard9 from '../Components/HomeCard9'
import HomeCard10 from '../Components/HomeCard10'
import HomeCardPro from '../Components/HomeCardPro'




export default function Home({ setIsLoading }) {
    const categoryRefs = {
        Mobile: useRef(null),
        Car: useRef(null),
        Property: useRef(null),
        Bikes: useRef(null),
        Jobs: useRef(null),
        Hostels: useRef(null),
        Electronics: useRef(null),
        Furniture: useRef(null),

    };

    const scrollToCategory = (category) => {
        const ref = categoryRefs[category];
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    // useEffect(() => {
    //     // Reload the page if no hash exists
    //     if (!window.location.hash) {
    //       // Set a timeout to allow React to finish rendering before reload
    //       const timer = setTimeout(() => {
    //         window.location.hash = "reloaded";
    //         window.location.reload();
    //       }, 0);
    
    //       return () => clearTimeout(timer); // Clean up the timer
    //     }
    //   }, []);

    return (
        <div>
            <Add  scrollToCategory={scrollToCategory}/>
            <Category scrollToCategory={scrollToCategory} />
            <br />
            <br />
            <div ><HomeCardPro setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Car}><HomeCard setIsLoading={setIsLoading}/></div>
            <div ><HomeCard9 setIsLoading={setIsLoading}/></div>
            <div ><HomeCard10 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Bikes} ><HomeCard1 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Property}><HomeCard2 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Jobs}><HomeCard3 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Mobile}><HomeCard4 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Hostels}><HomeCard5 setIsLoading={setIsLoading} /></div>
            <div ref={categoryRefs.Electronics}><HomeCard6 setIsLoading={setIsLoading}/></div>
            <div ref={categoryRefs.Furniture}><HomeCard7 setIsLoading={setIsLoading}/></div>
            <div ><HomeCard8 setIsLoading={setIsLoading}/></div>
            {/* <HomeCard8 /> */}

            <br />
            <br />
            <EndAdd />
            <br />
            <BannerAdd />
        </div>
    )
}
