import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../store/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "../Admin.css"

export const AdminUser   = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const { authorizationToken } = useAuth();

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }, [authorizationToken]);

  const deleteUser = async (id) => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/users/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();
      toast.success(data.message);
      if (response.ok) {
        getAllUsersData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  const formatDate = (dateString) => {
    const options = {  year: 'numeric', month: 'long',day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === "") {
      setFilteredUsers(users);
    } else {
      const filteredUsers = users.filter((user) => {
        return (
          user.username.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
        );
      });
      setFilteredUsers(filteredUsers);
    }
  };
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredUsers(users);
    }
  }, [users, searchQuery]);

  const indexOfLastUser   = currentPage * usersPerPage;
  const indexOfFirstUser   = indexOfLastUser   - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser  , indexOfLastUser  );

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleUsersPerPage = (e) => {
    setUsersPerPage(e.target.value);
  };

  const curUser  = (user) => {
    return (
      <tr key={user._id}>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{user.phone}</td>
        <td>{formatDate(user.createdAt)}</td>
        <td>{formatDate(user.lastLogin)}</td>
        <td><Link to={`${user._id}/edit`} className="edit">Edit</Link></td>
        <td><button  type="button" className="btn btn-danger" onClick={()=>deleteUser (user._id)}>Delete</button></td>
      </tr>
    );
  };

  return (
    <>
      <section className="admin-users-section">
        <div className="container">
          <h1>Admin Users Data</h1>
          <p> Total Registered Users: {users.length} </p>
          <input
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by username or email"
          />
          <select value={usersPerPage} onChange={handleUsersPerPage}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="container admin-users">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>date & time</th>
                <th>Last Login </th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map(curUser )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => paginate(currentPage - 1)}>Previous</button>
          )}
          {[...Array(totalPages).keys()].map((page, i) => (
            <button
              key={i}
              onClick={() => paginate(page + 1)}
              className={currentPage === page +  1 ? "active" : ""}
            >
              {page + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button onClick={() => paginate(currentPage + 1)}>Next</button>
          )}
        </div>
      </section>
    </>
  );
};