import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../store/auth";
import { toast } from "react-toastify";
import "../Admin.css";

export const AdminContact = () => {
  const [contactData, setContactData] = useState([]);

  const { authorizationToken } = useAuth();

  const getContcatData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setContactData(data);
        // console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [authorizationToken]);

  const deleteContactById = async (_id) => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/contacts/delete/${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        getContcatData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContcatData();
  }, [getContcatData]);
  return (
    <>
      <section className="admin-users-section">
        <div className="container">
          <h1>Admin Contact Data </h1>
          <p> Total Contact: {contactData.length} </p>
        </div>
        <div className="list-group contact-box">
    
          {contactData.map((curContactData, index) => {
            const { username, email, message, _id } = curContactData;
            return (
                  <div
                    key={index}
                    className="list-group-item list-group-item-action active col single-box"
                    aria-current="true"
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1"> {username}</h5>
                      <small>
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteContactById(_id)}
                        >
                          delete
                        </button>
                      </small>
                    </div>
                    <small className="contact-email">{email}</small>
                    <p className="mb-1">{message}</p>
                  </div>
           
            );
          })}
  
        </div>
      </section>
      {/* <section className="admin-contacts-section">
        <h1>Admin Contact Data </h1>
        <p> Total Contact: {contactData.length} </p>
        <div className="container admin-users">
          {contactData.map((curContactData, index) => {
            const { username, email, message, _id } = curContactData;
            return (
              <div key={index}>
                <p>{username}</p>
                <p>{email}</p>
                <p>{message}</p>
                <button className="btn" onClick={() => deleteContactById(_id)}>
                  delete
                </button>
              </div>
            );
          })}
        </div>
      </section> */}
    </>
  );
};
