import React, { useEffect, useState } from "react";
import "../../../Components/Contact.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../store/auth";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";

const Plots = ({ setIsLoading }) => {
  const [formData, setFormData] = useState({
    email: "",
    rent: "",
    type: "",
    listedby: "",
    plotarea: "",
    length: "",
    breadth: "",
    projectname: "",
    title: "",
    description: "",
    aria: "",
    wnumber: "",
  });
  const [images, setImages] = useState([]); // State for handling file upload
  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        email: user.email, // Set the user's email when the component mounts
      }));
    }
  }, [user]); // Run this effect when the user changes

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(URL.createObjectURL(files[i]));
    }
    setSelectedImages(images);
    setImages(event.target.files); 
  };

  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setFormData({ ...formData, aria: value }); // Update location in formData
    setErrorMessage(""); // Clear any previous error message

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location found"); // Set error message if fetch fails
      }
    }
  };

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, aria: value || "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.wnumber.startsWith("91")) {
      toast.error("Please without the country code in your WhatsApp number.");
      return;
    }

    if (images.length > 4)  {
      toast.error("Please upload exactly 4 images before submitting.");
      return;
    }
    if (formData.rent >= 0 && formData.rent <= 10) {
      toast.error("Please enter a price greater than 10.");
      return;
    }
    
    const data = new FormData();
    // Append the selected file to the form data
    data.append("formData", JSON.stringify(formData)); // Append the form data

    for (let i = 0; i < images.length; i++) {
      data.append("images", images[i]);
    }

    setIsLoading(true)
    
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/plots`, {
        method: "POST",
        body: data,
      });
      const result = await response.json();
      if (response.ok) {
        toast.success("Form submitted successfully");
        navigate("/");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }finally{
      setIsLoading(false)
    }
  };
  return (
    <div className="contact-container">
      <div className="contact-content">
        <h1>Lands & plots </h1>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-3">
            <label htmlFor="disabledTextInput" className="form-label">
              Category
            </label>
            <input
              type="text"
              name="plots"
              value="plots"
              id="disabledTextInput"
              className="form-control"
              placeholder="Lands & plots"
              disabled
            />
          </div> */}
          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              name="email"
              autoComplete="off"
              className="form-control"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              price*
            </label>
            <input
              type="Number"
              name="rent"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
              onWheel={(e) => e.target.blur()} 
            />
            {formData.rent >= 0 && formData.rent <= 10 && (
               <div style={{ color: "red" }}>
                Please enter a Rent greater than 10.
                </div>
            )}
          </div>

          <div className="mb-3">
            <select
              name="type"
              className="form-select form-select-sm"
              aria-label="Small select example"
              onChange={handleChange}
              required
            >
              <option selected="">Select Type*</option>
              <option value="For Rent">For Rent</option>
              <option value="For sale">For sale</option>
            </select>
          </div>

          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="listedby"
              onChange={handleChange}
              required
            >
              <option selected="">Listed by*</option>
              <option value="Builder">Builder</option>
              <option value="Dealer">Dealer</option>
              <option value="Owner">Owner</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Plot area
            </label>
            <input
              type="number"
              name="plotarea"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
              onWheel={(e) => e.target.blur()} 
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Length
            </label>
            <input
              type="number"
              name="length"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
              onWheel={(e) => e.target.blur()} 
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Breadth
            </label>
            <input
              type="number"
              name="breadth"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
              onWheel={(e) => e.target.blur()} 
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Project Name
            </label>
            <input
              type="text"
              name="projectname"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Ad title*
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Description*
            </label>
            <textarea
              type="text"
              name="description"
              className="form-control"
              cols="30"
              rows="4"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="aria" className="form-label">
              Location*
            </label>
            <Autocomplete
              style={{
                width: "100%",
                height: 50,
                background: "white",
                borderRadius: 20,
              }}
              freeSolo
              autoComplete
              autoHighlight
              options={myOptions}
              onInputChange={getDataFromAPI} // Update suggestions on input change
              onChange={handleAutocompleteChange} // Set full location on selection
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  placeholder="Select your Area, State"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      height: 50,
                      borderRadius: 20,
                      border: "1px solid black",
                      fontSize: "16px",
                      fontWeight: "700",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src="images/map-icon.png"
                          alt="Search Icon"
                          style={{ width: 26, height: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorMessage}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Your Whatsapp Number*
            </label>
            <TextField
              type="text"
              name="wnumber"
              className="form-control"
              id="wnumber"
              placeholder=" 1234567890"
              required
              value={formData.wnumber}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="images/91.png" // Your logo image
                      alt="Logo"
                      style={{ width: 50, height: 50 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Upload multiple image* ( Maximum 4 images are upload )
            </label>
            <input
              type="file"
              name="image"
              className="form-control"
              id="image"
              multiple
              required
              onChange={handleFileChange}
            />
          </div>

          {selectedImages.length > 0 && (
            <div>
              <div className="image-boxes">
                {Array(4)
                  .fill()
                  .map((_, index) => (
                    <div key={index} className="image-box">
                      {selectedImages[index] ? (
                        <img
                          src={selectedImages[index]}
                          alt={` ${index + 1}`}
                        />
                      ) : (
                        <div className="image-placeholder">{index + 1}</div>
                      )}
                      {/* {selectedImages[index] && (
                        <button onClick={() => handleRemoveImage(index)}>
                          Remove
                        </button>
                      )} */}
                    </div>
                  ))}
              </div>
            </div>
          )}

          <button type="submit" className="form-submit-button">
            Submit Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default Plots;
