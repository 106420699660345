import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import "./CardDetails.css";
import default_pic from "../../default-pic.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/auth";

Modal.setAppElement("#root");

function OthersDetails() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [mainImage, setMainImage] = useState("");
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Index for the image navigation
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    const getOthersDetails = async () => {
      try {
        const response = await fetch(
          `https://in.quiko.in/api/sell/others/${id}`,
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (!data.response_data) {
          throw new Error("No data received from server");
        }
        setCar(data.response_data);
        setMainImage(data.response_data.imageUrl[0]);
      } catch (error) {
        console.error("Error fetching car details:", error);
        setError(error.message);
      } 
    };

    getOthersDetails();
    
  }, [id]);

  useEffect(() => {
    if (car) {
      setMainImage(car.imageUrl[currentIndex]);
    }
  }, [currentIndex, car]);

    //=================== change webiste title ===============
  // =================================================
  useEffect(() => {
    if (car && car.title) {
      document.title = `${car.title}`;
    } else {
      document.title = "Quiko - Free Classified Ads In india";
    }
  }, [car]);

  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToNextImage = () => {
    if (car) {
      setCurrentIndex((prevIndex) =>
        prevIndex === car.imageUrl.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const goToPrevImage = () => {
    if (car) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? car.imageUrl.length - 1 : prevIndex - 1
      );
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!car) {
    return <div>No car data available.</div>;
  }

  const {
    imageUrl,
    brand,
    price,
    subcate,
    title,
    description,
    aria,
    wnumber,
    email,
    createdAt,
  } = car;


// Update the avatar click handler
const handleAvatarClick = () => {
  if (!isLoggedIn) {
    setRedirectToLogin(true);
  } else {
    // If the user is logged in, navigate to the user profile page
    navigate(`/user-profile/${email}`, {
      state: { aria, wnumber },
    });
  }
};
const handleMakeChatClick = () => {
  if (!isLoggedIn) {
    setRedirectToLogin(true);
  } else {
    // If the user is logged in, proceed with making a chat
    window.open(`https://wa.me/91${wnumber}`, "_blank");
  }
};

if (redirectToLogin) {
  return <Navigate to="/login" />;
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB');
};

  return (
    <section className="product">
      <div className="product__photo">
        <div className="photo-container">
          {mainImage && (
            <img
              src={`https://in.quiko.in/${mainImage}`}
              alt={title}
              className="main-image"
              onClick={openModal}
            />
          )}
        </div> 
        <div className="thumbnail-container">
          {imageUrl &&
            imageUrl.map((img, index) => (
              <img
                key={index}
                src={`https://in.quiko.in/${img}`}
                alt={`${title} view ${index + 1}`}
                className={`thumbnail ${mainImage === img ? "active" : ""}`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
        </div>
      </div>
      <div className="product__info">
        
        <div className="title">
          <h1>{title}</h1>
          <span>Others</span>
        </div>
        <div className="price">
          R$ <span style={{ fontSize: "30px" }}>{price}</span>
        </div>
        <div className="description">
          <h3>Brand : {brand}</h3>
          <p>{description}</p>
          <ul>
            <li>Category : {subcate}</li>
            <li>
              Location : {aria}
            </li>
          </ul>
          <h3>Posted At  : {formatDate(createdAt)}</h3>
        </div>

          <button className="buy--btn" onClick={handleMakeChatClick}>Make a Chat</button>


        <div className="avatar-div">
           <button onClick={handleAvatarClick}><img src={default_pic} alt="Avatar" class="avatar"/></button>
           <div className="tooltip-text">Show user profile</div>
        </div>
        <p className="report-p"><a href="mailto:quikosell@gmail.com" style={{color:"black"}} >Report a spam</a></p>
        
      </div>

      {/* Modal for Fullscreen Image with Navigation Buttons */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Full Screen Image"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-content">
          <button onClick={goToPrevImage} className="prev-btn">
            &#10094; {/* Left Arrow */}
          </button>
          <img
            src={`https://in.quiko.in/${mainImage}`}
            alt={title}
            className="fullscreen-image"
          />
          <button onClick={goToNextImage} className="next-btn">
            &#10095; {/* Right Arrow */}
          </button>
          <button onClick={closeModal} className="close-modal-btn">
            Close
          </button>
        </div>
      </Modal>
    </section>
  );
}

export default OthersDetails;
