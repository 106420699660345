import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../store/auth"; // Adjust the import path as necessary
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./ManagePost.css"; // Add custom styles here
import { Tooltip } from "@mui/material";

export default function ManageElectronics() {
  const [products, setProducts] = useState([]);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [truncatedDescriptions, setTruncatedDescriptions] = useState({});

  const { user } = useAuth(); // Get the current user data

  const getElectronics = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/electronics`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        // Filter the products to only include those with the user's email
        const filteredProducts = data.response_data.filter(
          (product) => product.email === user.email
        );
        setProducts(filteredProducts);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);

  const deleteElectronics = useCallback(
    async (id, imageUrl) => {
      try {
        const response = await fetch(
          `https://in.quiko.in/api/sell/electronics/delet/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${user.token}`, // If your API requires token authentication
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ imageUrl }),
          }
        );
        if (response.ok) {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== id)
          );
          toast.success("Delete successful");
        } else {
          console.log("Failed to delete the electronics.");
        }
      } catch (error) {
        console.log("service page error ", error);
      }
    },
    [user]
  );

  const handleDeleteClick = (id, imageUrl) => {
    deleteElectronics(id, imageUrl);
  };

  useEffect(() => {
    if (user) {
      getElectronics();
    }
  }, [user, getElectronics]);

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/electronics/${_id}`);
  };
  
  const handleEditClick = (id) => {
    navigate(`/edit/electronics/${id}`);
  };

  const handleFeaturedPost = (_id, title, category) => {
    navigate(`/featuredpost`, {
      state: { email: user.email, postId: _id, postTitle: title , category: category},
    });
  };
    // ============= read more =============
    useEffect(() => {
      const truncatedDescriptions = {};
      products.forEach((product) => {
        if (product.description.length > 100) {
          truncatedDescriptions[product._id] = `${product.description.substring(0, 100)}...`;
        } else {
          truncatedDescriptions[product._id] = product.description;
        }
      });
      setTruncatedDescriptions(truncatedDescriptions);
    }, [products]);
  
    const handleReadMore = (id) => {
      setShowFullDescription((prevShowFullDescription) => ({ ...prevShowFullDescription, [id]: true }));
    };
  
    const handleShowLess = (id) => {
      setShowFullDescription((prevShowFullDescription) => ({ ...prevShowFullDescription, [id]: false }));
    };

  return products.map((curEle, index) => {
    const { _id, title, imageUrl, price, aria, description, category } = curEle;
    return (
      <div className="manage-post-container" key={index}>
        <div className="post-image" onClick={() => handleCardClick(_id)}>
          <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Post" />
        </div>

        <div className="post-details">
          <div className="post-price">
            <h3>₹{price}</h3>
          </div>
          <div className="post-header">
            <h3>{title}</h3>
            
            <p className="post-description">
              {showFullDescription[_id] ? description : truncatedDescriptions[_id]}
              {description.length > 100 && !showFullDescription[_id] && (
                <span onClick={() => handleReadMore(_id)} style={{color:"blue"}}>read more</span>
              )}
              {showFullDescription[_id] && (
                <span onClick={() => handleShowLess(_id)} style={{color:"blue"}}>...show less</span>
              )}
            </p>

            <p className="post-location">Location: {aria}</p>
          </div>
          <div className="post-actions" style={{ gap: "5px", display: "flex", flexDirection: "row" }}>
            <button className="delete-btn btn-3" onClick={() => handleDeleteClick(_id, imageUrl)}>
              Delete
            </button>
            <button className="custom-btn btn-2" onClick={() => handleEditClick(_id)}>
              Edit
            </button>
            <Tooltip placement="top" arrow slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}>
              <button className="promote-ad-btn btn-4">
              <button
                onClick={()=> handleFeaturedPost(_id, title, category)}
                style={{color:"white"}}
              >
                Featured
              </button>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  });
}
