import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import default_post from "../../Assets/images/default-post.jpeg"

function SeeAllHouse({ setIsLoading }) {
  const { location, searchProduct } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 24; // Set to 25

  const [renthouse, setRentHouse] = useState([]);
  const [salehouse, setSaleHouse] = useState([]);
  const [rentshop, setRentShop] = useState([]);
  const [saleshop, setSaleShop] = useState([]);
  const [plots, setPlots] = useState([]);

  //=================== change webiste title ===============
  // =================================================
  useEffect(() => {
    document.title = `Quiko - Buy , Sell & Rent Properties in India | Real Estate Apartments  `; // Update title dynamically
}, []);

  const getRentHouse = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/renthouse`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setRentHouse(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const getSaleHouse = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/salehouse`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setSaleHouse(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const getRentShop = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/rentshop`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setRentShop(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const getSaleShop = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/saleshop`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setSaleShop(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const getPlots = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/plots`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setPlots(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    getRentHouse();
    getSaleHouse();
    getRentShop();
    getSaleShop();
    getPlots();
  }, [getRentHouse, getSaleHouse, getRentShop, getSaleShop, getPlots]);

  const [activeTab, setActiveTab] = useState("rentHouse");

  const filteredRentHouse = renthouse
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const filteredSaleHouse = salehouse
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const filteredRentShop = rentshop
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const filteredSaleShop = saleshop
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const filteredPlots = plots
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;

  const currentRentHouse = filteredRentHouse.slice(
    indexOfFirstCar,
    indexOfLastCar
  );
  const currentSaleHouse = filteredSaleHouse.slice(
    indexOfFirstCar,
    indexOfLastCar
  );
  const currentRentShop = filteredRentShop.slice(
    indexOfFirstCar,
    indexOfLastCar
  );
  const currentSaleShop = filteredSaleShop.slice(
    indexOfFirstCar,
    indexOfLastCar
  );
  const currentPlots = filteredPlots.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(filteredRentHouse.length / carsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPaginationNumbers = () => {
    const pageNumbers = [];

    if (totalPages > 1) {
      if (currentPage === 1) {
        pageNumbers.push(1, 2, "...");
      } else if (currentPage === 2) {
        pageNumbers.push(1, 2, 3);
      } else if (currentPage >= 3) {
        pageNumbers.push(currentPage - 1, currentPage, "...");
      }
    } else if (totalPages === 1) {
      pageNumbers.push(1);
    }

    return pageNumbers;
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) return `${months} month${months > 1 ? "s" : ""} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  };

  const isLatest = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const differenceInHours = (now - postDate) / (1000 * 60 * 60);
    return differenceInHours <= 24; // Post is within 24 hours
  };

  const navigate = useNavigate();
  const handleRentHouse = (_id) => {
    navigate(`/renthouse/${_id}`);
  };
  const handleSaleHouse = (_id) => {
    navigate(`/salehouse/${_id}`);
  };
  const handleRentShop = (_id) => {
    navigate(`/rentshop/${_id}`);
  };
  const handleSaleShop = (_id) => {
    navigate(`/saleshop/${_id}`);
  };
  const handlePlots = (_id) => {
    navigate(`/plots/${_id}`);
  };

  return (
    <div className="all-cars-container" style={{ marginTop: "150px" }}>
      <h2 className="homecard-title bikes" id="homecard-text" style={{fontSize:"30px"}}>
        All Properties
      </h2>
      <div className="tab-buttons">
        <button
          className={activeTab === "rentHouse" ? "active" : ""}
          onClick={() => setActiveTab("rentHouse")}
        >
          Rent House
        </button>
        <button
          className={activeTab === "saleHouse" ? "active" : ""}
          onClick={() => setActiveTab("saleHouse")}
        >
          Sale House
        </button>
        <button
          className={activeTab === "rentShop" ? "active" : ""}
          onClick={() => setActiveTab("rentShop")}
        >
          Rent Shop
        </button>
        <button
          className={activeTab === "saleShop" ? "active" : ""}
          onClick={() => setActiveTab("saleShop")}
        >
          Sale Shop
        </button>
        <button
          className={activeTab === "plots" ? "active" : ""}
          onClick={() => setActiveTab("plots")}
        >
          Plots
        </button>
      </div>
      {activeTab === "rentHouse" && (
        <div className="all-cars" style={{ marginTop: "50px" }}>
          {currentRentHouse.length > 0 ? (
            currentRentHouse.map((curEle, index) => {
              const { title, imageUrl, rent, _id, aria, createdAt, isPremium } = curEle;
              return (
                <div className="sell-all-post-child" key={index}>
                  <div
                    className="child post-container"
                    key={index}
                    onClick={() => handleRentHouse(_id)}
                    style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
                  >
                    {isLatest(createdAt) && (
                      <span className="latest-label">Latest</span>
                    )}
                    {isPremium && <span className="premium-badge">FEATURED</span>}
                    <img
                      src={`https://in.quiko.in/${imageUrl[0]}`}
                      alt="Card 1"
                      
                      onError={(e) => (e.target.src = default_post)}
                    />
                    <p>For Rent</p>
                    <h4 className="">Rs {rent}</h4>
                    <h6>{timeAgo(createdAt)}</h6>
                    <h5>{title}</h5>
                    <h6>Location: {aria}</h6>
                    <h6>Clicked For More Update</h6>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="notfound-post-text">No posts found </h2>
          )}
        </div>
      )}

      {activeTab === "saleHouse" && (
        <div className="all-cars" style={{ marginTop: "50px" }}>
          {currentSaleHouse.length > 0 ? (
            currentSaleHouse.map((curEle, index) => {
              const { title, imageUrl, rent, _id, aria, createdAt, isPremium } = curEle;
              return (
                <div className="sell-all-post-child" key={index}>
                  <div
                    className="child post-container"
                    key={index}
                    onClick={() => handleSaleHouse(_id)}
                    style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
                  >
                    {isLatest(createdAt) && (
                      <span className="latest-label">Latest</span>
                    )}
                    {isPremium && <span className="premium-badge">FEATURED</span>}
                    <img
                      src={`https://in.quiko.in/${imageUrl[0]}`}
                      alt="Card 1"
                      
                      onError={(e) => (e.target.src = default_post)}
                    />
                    <p>For Sale</p>
                    <h4 className="">Rs {rent}</h4>
                    <h6>{timeAgo(createdAt)}</h6>
                    <h5>{title}</h5>
                    <h6>Location: {aria}</h6>
                    <h6>Clicked For More Update</h6>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="notfound-post-text">No posts found </h2>
          )}
        </div>
      )}

      {activeTab === "rentShop" && (
        <div className="all-cars" style={{ marginTop: "50px" }}>
          {currentRentShop.length > 0 ? (
            currentRentShop.map((curEle, index) => {
              const { title, imageUrl, rent, _id, aria, createdAt, isPremium } = curEle;
              return (
                <div className="sell-all-post-child" key={index}>
                  <div
                    className="child post-container"
                    key={index}
                    onClick={() => handleRentShop(_id)}
                    style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
                  >
                    {isLatest(createdAt) && (
                      <span className="latest-label">Latest</span>
                    )}
                    {isPremium && <span className="premium-badge">FEATURED</span>}
                    <img
                      src={`https://in.quiko.in/${imageUrl[0]}`}
                      alt="Card 1"
                      onError={(e) => (e.target.src = default_post)}
                    />
                    <p>For Rent</p>
                    <h4 className="">Rs {rent}</h4>
                    <h6>{timeAgo(createdAt)}</h6>
                    <h5>{title}</h5>
                    <h6>Location: {aria}</h6>
                    <h6>Clicked For More Update</h6>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="notfound-post-text">No posts found </h2>
          )}
        </div>
      )}

      {activeTab === "saleShop" && (
        <div className="all-cars" style={{ marginTop: "50px" }}>
          {currentSaleShop.length > 0 ? (
            currentSaleShop.map((curEle, index) => {
              const { title, imageUrl, rent, _id, aria, createdAt, isPremium } = curEle;
              return (
                <div className="sell-all-post-child" key={index}>
                  <div
                    className="child post-container"
                    key={index}
                    style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
                    onClick={() => handleSaleShop(_id)}
                  >
                    {isLatest(createdAt) && (
                      <span className="latest-label">Latest</span>
                    )}
                    {isPremium && <span className="premium-badge">FEATURED</span>}
                    <img
                      src={`https://in.quiko.in/${imageUrl[0]}`}
                      alt="Card 1"
                      onError={(e) => (e.target.src = default_post)}
                    />
                    <p>For Sale</p>
                    <h4 className="">Rs {rent}</h4>
                    <h6>{timeAgo(createdAt)}</h6>
                    <h5>{title}</h5>
                    <h6>Location: {aria}</h6>
                    <h6>Clicked For More Update</h6>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="notfound-post-text">No posts found </h2>
          )}
        </div>
      )}

      {activeTab === "plots" && (
        <div className="all-cars" style={{ marginTop: "50px" }}>
          {currentPlots.length > 0 ? (
            currentPlots.map((curEle, index) => {
              const { title, imageUrl, rent, _id, aria, createdAt , isPremium } = curEle;
              return (
                <div className="sell-all-post-child" key={index}>
                  <div
                    className="child post-container"
                    key={index}
                    style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
                    onClick={() => handlePlots(_id)}
                  >
                    {isLatest(createdAt) && (
                      <span className="latest-label">Latest</span>
                    )}
                    {isPremium && <span className="premium-badge">FEATURED</span>}
                    <img
                      src={`https://in.quiko.in/${imageUrl[0]}`}
                      alt="Card 1"
                      onError={(e) => (e.target.src = default_post)}
                    />
                    <p>For Sale</p>
                    <h4 className="">Rs {rent}</h4>
                    <h6>{timeAgo(createdAt)}</h6>
                    <h5>{title}</h5>
                    <h6>Location: {aria}</h6>
                    <h6>Clicked For More Update</h6>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 className="notfound-post-text">No posts found </h2>
          )}
        </div>
      )}
      {/* Pagination Controls */}
      <div
        className="pagination"
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        )}
        {getPaginationNumbers().map((page, i) => (
          <button
            key={i}
            onClick={() => typeof page === "number" && paginate(page)}
            className={currentPage === page ? "active" : ""}
            disabled={page === "..."}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default SeeAllHouse;
