import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../store/auth"; // Adjust the import path as necessary
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./ManagePost.css"; // Add custom styles here

export default function ManageSaleHouse() {
  const [products, setProducts] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [truncatedDescriptions, setTruncatedDescriptions] = useState({});

  const { user } = useAuth();

  const getSaleHouse = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/salehouse`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        const filteredProducts = data.response_data.filter(
          (product) => product.email === user.email
        );
        setProducts(filteredProducts);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);

  const deleteSaleHouse = useCallback(
    async (id, imageUrl) => {
      try {
        const response = await fetch(
          `https://in.quiko.in/api/sell/salehouse/delet/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ imageUrl }),
          }
        );
        if (response.ok) {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== id)
          );
          toast.success("Delete successful");
        } else {
          console.log("Failed to delete the sale house.");
        }
      } catch (error) {
        console.log("service page error ", error);
      }
    },
    [user]
  );

  const handleDeleteClick = (id, imageUrl) => {
    deleteSaleHouse(id, imageUrl);
  };

  useEffect(() => {
    if (user) {
      getSaleHouse();
    }
  }, [user, getSaleHouse]);

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/salehouse/${_id}`);
  };
  
  const handleEditClick = (id) => {
    navigate(`/edit/salehouse/${id}`);
  };

  const handleFeaturedPost = (_id, title, category) => {
    navigate(`/featuredpost`, {
      state: { email: user.email, postId: _id, postTitle: title , category: category},
    });
  };

  // ============= read more =============
  useEffect(() => {
    const truncatedDescriptions = {};
    products.forEach((product) => {
      if (product.description.length > 100) {
        truncatedDescriptions[product._id] = `${product.description.substring(0, 100)}...`;
      } else {
        truncatedDescriptions[product._id] = product.description;
      }
    });
    setTruncatedDescriptions(truncatedDescriptions);
  }, [products]);

  const handleReadMore = (id) => {
    setShowFullDescription((prevShowFullDescription) => ({ ...prevShowFullDescription, [id]: true }));
  };

  const handleShowLess = (id) => {
    setShowFullDescription((prevShowFullDescription) => ({ ...prevShowFullDescription, [id]: false }));
  };

  return products.map((curEle, index) => {
    const { _id, title, imageUrl, rent, aria, description , category} = curEle;
    return (
      <div className="manage-post-container" key={index}>
        <div className="post-image" onClick={() => handleCardClick(_id)}>
          <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Sale House" />
        </div>

        <div className="post-details">
          <div className="post-price">
            <h3>₹{rent}</h3>
          </div>
          <div className="post-header">
            <h3>{title}</h3>
                        <p className="post-description">
              {showFullDescription[_id] ? description : truncatedDescriptions[_id]}
              {description.length > 100 && !showFullDescription[_id] && (
                <span onClick={() => handleReadMore(_id)} style={{color:"blue"}}>read more</span>
              )}
              {showFullDescription[_id] && (
                <span onClick={() => handleShowLess(_id)} style={{color:"blue"}}>...show less</span>
              )}
            </p>
            <p className="post-location">Location: {aria}</p>
          </div>
          <div
            className="post-actions"
            style={{ gap: "5px", display: "flex", flexDirection: "row" }}
          >
            <button
              className="delete-btn btn-3"
              onClick={() => handleDeleteClick(_id, imageUrl)}
            >
              Delete
            </button>
            <button
              className="custom-btn btn-2"
              onClick={() => handleEditClick(_id)}
            >
              Edit
            </button>
            <button className="promote-ad-btn btn-4">
              <button
                onClick={()=> handleFeaturedPost(_id, title, category)}
                style={{color:"white"}}
              >
                Featured
              </button>
            </button>
          </div>
        </div>
      </div>
    );
  });
}
