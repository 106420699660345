import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth";
import "./See-All.css";
import { useNavigate } from "react-router-dom";
import default_post from "../../Assets/images/default-post.jpeg"

function SeeAllBikes() {
  const [bikes, setBikes] = useState([]);
  const { location, searchProduct } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const bikesPerPage = 24; // Same as carsPerPage

  const getBikes = useCallback(async () => {

    try {
      const response = await fetch(`https://in.quiko.in/api/sell/bikes`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setBikes(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } 
  }, []);

  useEffect(() => {
    getBikes();

    document.title = `Quiko - Second Hands Bikes | Buy & Sell Used Bikes in India `;
  }, [getBikes]);

  const filteredBikes = bikes
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  const indexOfLastBike = currentPage * bikesPerPage;
  const indexOfFirstBike = indexOfLastBike - bikesPerPage;
  const currentBikes = filteredBikes.slice(indexOfFirstBike, indexOfLastBike);

  const totalPages = Math.ceil(filteredBikes.length / bikesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic to show numbers with ellipsis when appropriate
  const getPaginationNumbers = () => {
    const pageNumbers = [];

    if (totalPages > 1) {
      if (currentPage === 1) {
        pageNumbers.push(1, 2, '...');
      } else if (currentPage === 2) {
        pageNumbers.push(1, 2, 3);
      } else if (currentPage >= 3) {
        pageNumbers.push(currentPage - 1, currentPage, '...');
      }
    } else if (totalPages === 1) {
      pageNumbers.push(1);
    }

    return pageNumbers;
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const isLatest = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const differenceInHours = (now - postDate) / (1000 * 60 * 60);
    return differenceInHours <= 24; // Post is within 24 hours
  };

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/bikes/${_id}`);
  };

  return (
    <div className="all-cars-container" style={{ marginTop: "170px" }}>
      <h1 className="homecard-title bikes" id="homecard-text">All Bikes</h1>
      <div className="all-cars" style={{ marginTop: "50px" }}>
        {currentBikes.map((curEle, index) => {
          const { _id, title, imageUrl, price, aria, createdAt ,isPremium } = curEle;
          return (
            <div className="sell-all-post-child" key={index}>
              <div className="child post-container" onClick={() => handleCardClick(_id)} style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }>
                {isLatest(createdAt) && <span className="latest-label">Latest</span>}
                {isPremium && <span className="premium-badge">FEATURED</span>}
                <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Bike"  onError={(e) => (e.target.src = default_post)}/>
                <h4>Rs {price}</h4>
                <h6>{timeAgo(createdAt)}</h6>
                <h5>{title}</h5>
                <h6>Location: {aria}</h6>
                <h6>Clicked For More Update</h6>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination Controls */}
      <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        )}
        {getPaginationNumbers().map((page, i) => (
          <button
            key={i}
            onClick={() => typeof page === 'number' && paginate(page)}
            className={currentPage === page ? "active" : ""}
            disabled={page === '...'}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default SeeAllBikes;
