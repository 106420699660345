import * as React from "react";
import ManageCar from "../Components/ManagePost/ManageCar";
import ManageBikes from "../Components/ManagePost/ManageBikes";
import ManageMobile from "../Components/ManagePost/ManageMobile";
import ManageJobs from "../Components/ManagePost/ManageJobs";
import ManageFurniture from "../Components/ManagePost/ManageFurniture";
import ManageElectronics from "../Components/ManagePost/ManageElectronics";
import ManageHostel from "../Components/ManagePost/ManageHostel";
import ManageRentHouse from "../Components/ManagePost/ManageRentHouse";
import ManageSaleHouse from "../Components/ManagePost/ManageSaleHouse";
import ManageRentShop from "../Components/ManagePost/ManageRentShop";
import ManagePlots from "../Components/ManagePost/ManagePlots";
import ManageSaleShop from "../Components/ManagePost/ManageSaleShop";
import ManageOthers from "../Components/ManagePost/ManageOthers";
import ManageCloths from "../Components/ManagePost/ManageCloths";
import ManageSelfDrives from "../Components/ManagePost/ManageSelfDrives";

export default function YourPost() {
  return (
    <>
      <h1
        className="homecard-title bikes user-profile-title"
        style={{ marginTop: "30px", width: "100%" }}
      >
        Your Post
      </h1>
      
      <div
        style={{
          padding: "10px",
          margin: "auto",
          maxWidth: "500px",
          left:0,
          flexGrow: 1,
          marginBottom: "20px",
          marginTop:"10px",
          backgroundColor: "#fff", // You can change this color as per light or dark mode
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // To match the Paper component shadow
          borderRadius: "8px",
          border:"2px solid black"
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            gap: "20px",
            marginLeft:"-20px"
          }}
        >
          <ManageCar />
          <ManageCloths />
          <ManageSelfDrives />
          <ManageBikes />
          <ManageMobile />
          <ManageJobs />
          <ManageFurniture />
          <ManageElectronics />
          <ManageHostel />
          <ManageRentHouse />
          <ManageSaleHouse />
          <ManageRentShop />
          <ManageSaleShop />
          <ManagePlots />
          <ManageOthers />
        </div>
      </div>
    </>
  );
}
